import { Chips } from "primereact/chips";
import { useEffect } from "react";
import { Icon } from "../Icon";
import styles from "./MultiInput.module.css";
import inputStyles from "../Inputs/Input.module.css";

export interface MultiCodeInputProps {
  label: string;
  placeholder?: string;
  values?: string[];
  setValues: (values: string[]) => void;
  validationError?: string;
  required?: boolean;
}

export default function MultiCodeInput({
  label,
  placeholder,
  values = [],
  setValues,
  validationError,
  required,
}: MultiCodeInputProps) {
  const getInput = () => {
    const elem = document.querySelector(".p-chips-input-token");
    const input = elem?.getElementsByTagName("input");

    return input ? input[0] : undefined;
  };

  useEffect(() => {
    // Library didn’t provide prop to change input type, so we added type.
    const input = getInput();
    if (input) input.setAttribute("type", "number");
  }, []);

  function onDelete(item: number) {
    const newItems = [...values];
    const itemIndex = newItems.findIndex((i) => i === item.toString());
    newItems.splice(itemIndex, 1);
    setValues(newItems);
  }

  function Item(item: number) {
    return (
      <div className={styles.item}>
        <div className={styles.name}>{item}</div>
        <div
          className={styles.deleteIcon}
          onClick={() => onDelete(item)}
          onKeyDown={() => onDelete(item)}
          role="button"
          aria-label="Close"
        >
          <Icon name="x" color="Grey-400" size={18} hoverEffect />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span className="strong">
        {required && <span className={inputStyles.requiredIcon}>*</span>}
        {label}
      </span>
      <Chips
        placeholder={values.length === 0 ? placeholder : undefined}
        value={values}
        onChange={(e) => setValues(e.value || [])}
        onBlur={() => {
          // Clear input on blur by simulate enter press
          const input = getInput();

          if (input) {
            const event = new KeyboardEvent("keydown", {
              keyCode: 13,
              bubbles: true,
            });
            input.dispatchEvent(event);
          }
        }}
        itemTemplate={Item}
        allowDuplicate={false}
        separator=","
        removable={false}
      />
      {validationError ? (
        <div className={styles.validationError}>{validationError}</div>
      ) : null}
    </div>
  );
}
