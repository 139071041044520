import moment from "moment-timezone";
import {
  ChangeEvent,
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import {
  Product,
  ProductCategory,
  useProductCategories,
} from "../../core/api/products";
import {
  registerTransmitter,
  saveTransmitter,
} from "../../core/api/transmitters/transmitters";
import {
  EditTransmitterData,
  TransmitterCredentials,
  TransmitterModel,
} from "../../core/api/transmitters/types";
import {
  getIdentifierErrors,
  notifyApiErrors,
} from "../../core/helpers/helpers";
import {
  validateIdentifier,
  validateImei,
  validatePhoneNumberIfExists,
  validateSipUrl,
} from "../../core/helpers/validation";
import { useForceUpdate } from "../../core/hooks/useForceUpdate";
import useTranslations from "../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../core/SaveObjectContext/hooks";
import useUser from "../../core/user/useUser";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import DatePicker from "../../ui-lib/components/DateAndTime/DatePicker";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import InputContainer from "../../ui-lib/components/Inputs/InputContainer";
import TextArea from "../../ui-lib/components/Inputs/Textarea";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import { Column, Row, Spacer } from "../Layout/Layout";
import { getProductName } from "../../ui-lib/utils/getProductName";
import NumberInput from "../../ui-lib/components/Inputs/NumberInput";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Button from "../../ui-lib/components/Button/Button";
import styles from "./Transmitters.module.css";
import { DeleteTransmitteCredentialsModal } from "./DeleteTransmitterCredentialsModal";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import Icon from "../../ui-lib/components/Icon";
import { AddSipCredentialsModal } from "./AddSipCredentialsModal";
import { CopyToClipboard } from "../../ui-lib/components/CopyToClipboard/CopyToClipboard";
import { IconWithInfo } from "../../ui-lib/components/IconsWithInfo/IconWithInfo";

export declare type EditTransmitterActions = {
  submitForm: () => Promise<TransmitterEditResult>;
  reset: () => void;
};

export interface EditTransmitterProps {
  customerId?: number;
  transmitter?: TransmitterModel;
  credentials?: TransmitterCredentials[];
  uploadTransmitterData?: () => void;
  showBroken?: boolean;
  updateFormState?: Dispatch<SetStateAction<boolean>>;
  toObject?: boolean;
  onClose?: () => void;
  closeStockModal?: (createMore?: boolean, id?: number) => void;
  dataLoading?: boolean;
}

export interface TransmitterEditResult {
  status: boolean;
  transmitterId?: string | number;
  createMoreTransmitter?: boolean;
}

export const TransmitterEditForm = forwardRef<
  EditTransmitterActions,
  EditTransmitterProps
>((props: EditTransmitterProps, ref) => {
  const {
    customerId,
    transmitter,
    showBroken,
    updateFormState,
    uploadTransmitterData,
    credentials = [],
    toObject = false,
    onClose,
    closeStockModal,
    dataLoading,
  } = props;
  const t = useTranslations();
  const [isRemoveCredsVisible, setIsRemoveCredsVisible] = useState(false);
  const [credentialsHolder, setCredentialsHolder] =
    useState<TransmitterCredentials[]>();
  const formRef = useObjectFormRef();
  const { authenticatedRequest, dateFormat } = useUser();
  const { data: productCategories } = useProductCategories();
  const { data: organisations } = useOrganizationsTree();
  const [sipPassValue, setSipPassValue] = useState<string>("");
  const [sipNameValue, setSipNameValue] = useState<string>("");
  const [sipErrors, setSipErrors] = useState<{
    sipUsername: boolean;
    sipPassword: boolean;
  }>({ sipPassword: false, sipUsername: false });
  const [isAddSipVisible, setIsAddSipVisible] = useState(false);

  const findProductCategory = (id: number): number | undefined => {
    let category;
    if (productCategories) {
      for (const element of productCategories) {
        const result = element.products.find((prod) => prod.id === id);
        if (result) {
          category = element;
          break;
        }
      }
    }
    return category?.productCategoryId;
  };

  const getFormModel = (): EditTransmitterData => {
    if (transmitter) {
      const categoryId = findProductCategory(transmitter.productId);
      const warrenty = transmitter.warrenty
        ? moment.tz(transmitter?.warrenty, "UTC").toDate()
        : undefined;
      const leasingExpires = transmitter.leasingExpires
        ? moment.tz(transmitter.leasingExpires, "UTC").toDate()
        : undefined;
      const data = {
        comment: transmitter?.comment?.replace(/\\n/g, "\n") ?? "",
        sellerId: transmitter.organizationId || undefined,
        imei: transmitter.imei,
        id: transmitter.id,
        leasingExpires,
        missing: !!(
          transmitter.missing && transmitter.missing.toLowerCase() === "true"
        ),
        organizationId: transmitter.organizationId || customerId,
        puk: transmitter.puk,
        serialNumber: transmitter.serialNumber,
        pin: transmitter.pin,
        identifier: transmitter.identifier,
        version: transmitter.version,
        warrenty: transmitter !== undefined ? warrenty : undefined,
        productCategoryId: transmitter !== undefined ? categoryId : undefined,
        product: transmitter.productId,
        broken: transmitter.broken,
        callbackTo: transmitter.callbackTo,
        callbackTo2: transmitter.callbackTo2,
        model: transmitter.model,
        manufacturer: transmitter.manufacturer,
      };
      return data;
    }
    if (transmitter === null) {
      // if transmitter doesn't exist anymore the modal window should be closed
      onClose?.();
    }
    return {} as EditTransmitterData;
  };
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useObjectForm<EditTransmitterData>({
    defaultValues: useMemo(() => getFormModel(), [productCategories]),
  });

  useEffect(() => {
    if (updateFormState) {
      updateFormState(isDirty);
    }
  }, [isDirty]);

  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(true);
  const [isCredentialsLoading, setIsCredentialsLoading] = useState(false);
  const categories = useRef<IDropdownItem[]>([]);
  const products = useRef<IDropdownItem[]>([]);
  const forceUpdate = useForceUpdate();
  const [belongsToId, setBelongsToId] = useState(transmitter?.organizationId);
  const [createMore, setCreateMore] = useState(false);

  const getProduct = (id: number): Product | undefined => {
    let product;
    if (productCategories) {
      for (const element of productCategories) {
        const result = element.products.find((prod) => prod.id === id);
        if (result) {
          product = result;
          break;
        }
      }
    }
    return product;
  };
  const selectCategory = (item: IDropdownItem) => {
    setValue("productCategoryId", +item.id);
    categories.current?.forEach((r) => {
      r.isSelected = false;
    });
    item.isSelected = true;
    const prods = productCategories
      ?.find((p) => p.productCategoryId === item.id)
      ?.products.filter((pr) => pr.canBeInStock || toObject)
      .map((pr) => ({
        id: pr.id,
        name: getProductName(pr, t),
        isSelected: false,
      }));
    products.current = prods || [];
    forceUpdate();
  };
  const selectProduct = (item: IDropdownItem) => {
    setValue("product", +item.id);
    products.current?.forEach((r) => {
      r.isSelected = false;
    });
    item.isSelected = true;
    setSelectedProduct(getProduct(+item.id));
  };

  const setTransmitter = () => {
    if (transmitter) {
      const categoryId = findProductCategory(transmitter.productId);
      const category = categories.current?.find((cat) => cat.id === categoryId);
      setSelectedProduct(getProduct(transmitter.productId));
      if (category) {
        selectCategory(category);
      }
      const product = products.current?.find(
        (prod) => prod.id === transmitter.productId
      );
      if (product) {
        selectProduct(product);
      }
      setValue("organizationId", transmitter.organizationId || customerId);
    }
    const model = getFormModel();
    reset(model);
  };

  const isLegacySIP = () =>
    [4, 5, 6].includes(selectedProduct?.sipCredentialType!);

  const registerTransmitterCredentials = async (
    transmitterId: number | string
  ) => {
    let validationOk = true;
    const sipPass = getValues("sipPassword") ?? sipPassValue;
    const sipName = getValues("sipUsername") ?? sipNameValue;
    const registerFormData = {
      sipPassword: sipPass,
      sipUsername: sipName,
    };
    const newSipErrors = {
      sipPassword: false,
      sipUsername: false,
    };
    if (
      selectedProduct?.requireSipPassword &&
      (!sipPass || sipPass.length < 8)
    ) {
      validationOk = false;
      if (transmitter) {
        newSipErrors.sipPassword = true;
      } else {
        setError("sipPassword", { type: "custom" });
      }
    }
    if (
      selectedProduct?.requireSipUsername &&
      (!sipName || sipName.length < 8)
    ) {
      validationOk = false;
      if (transmitter) {
        newSipErrors.sipUsername = true;
      } else {
        setError("sipUsername", { type: "custom" });
      }
    }
    if (!validationOk && !isLegacySIP()) {
      setSipErrors(newSipErrors);
      return true;
    }

    clearErrors("sipUsername");
    clearErrors("sipPassword");
    setSipErrors({ sipPassword: false, sipUsername: false });
    setIsCredentialsLoading(true);
    try {
      await registerTransmitter(
        authenticatedRequest,
        transmitterId,
        registerFormData
      );
      if (transmitter) {
        setSipPassValue("");
        setSipNameValue("");
      }
      uploadTransmitterData?.();
      setValue("sipUsername", undefined);
      setValue("sipPassword", undefined);
    } catch (error: any) {
      notify({
        variant: "error",
        message: transmitter
          ? t("Errors:sip_credentials_short")
          : t("Errors:sip_credentials"),
      });
    } finally {
      setIsCredentialsLoading(false);
    }
  };

  useImperativeHandle<EditTransmitterActions, EditTransmitterActions>(
    ref,
    () => {
      const actions: EditTransmitterActions = {
        submitForm: async (): Promise<TransmitterEditResult> => {
          const returnResult: TransmitterEditResult = {
            status: true,
            createMoreTransmitter: createMore,
          };
          try {
            await handleSubmit(
              async (formData: EditTransmitterData): Promise<any> => {
                formData.comment = formData.comment?.replace(/\n/g, "\\n");
                formData.organizationId = formData.organizationId ?? customerId;
                formData.sipPassword = getValues("sipPassword") ?? sipPassValue;
                formData.sipUsername = getValues("sipUsername") ?? sipNameValue;
                try {
                  const result = await saveTransmitter(
                    formData,
                    authenticatedRequest
                  );
                  returnResult.status = true;
                  returnResult.transmitterId = result.data ?? formData.id;
                  if (closeStockModal) {
                    closeStockModal(createMore, returnResult.transmitterId);
                  }
                  setCreateMore(false);
                  selectCategory({} as IDropdownItem);
                  setSelectedProduct(undefined);
                } catch (error: any) {
                  notifyApiErrors(error.response.data.errors);
                  returnResult.status = false;
                }
              },
              () => {
                returnResult.status = false;
              }
            )().catch(() => {
              returnResult.status = false;
            });
          } catch (error) {
            returnResult.status = false;
          }
          return returnResult;
        },
        reset,
      };
      return actions;
    }
  );
  const loadFormState = async () => {
    setIsLoading(true);
    const newCategories = productCategories.map((r: ProductCategory) => ({
      id: r.productCategoryId,
      name: t(`Language:${r.name}`),
      isSelected: false,
    }));
    categories.current = newCategories;

    setTransmitter();
    setIsLoading(false);
  };

  const renderSipInputs = () => (
    <>
      {(selectedProduct?.requireSipPassword ||
        selectedProduct?.requireSipUsername) && (
        <div className={styles.editFormBlockWrapper}>
          {selectedProduct?.requireSipUsername && (
            <>
              {transmitter ? (
                <TextInput
                  placeholder={t(
                    "Administrator:password_constraints_minimum_length"
                  ).replace("{0}", "8")}
                  label={t("Transmitters:labels_sip_username")}
                  value={sipNameValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 8 && sipErrors.sipUsername) {
                      setSipErrors({
                        ...sipErrors,
                        sipUsername: false,
                      });
                    }
                    setSipNameValue(e.target.value);
                  }}
                  validationError={
                    sipErrors.sipUsername
                      ? t(
                          "Administrator:password_constraints_minimum_length"
                        ).replace("{0}", "8")
                      : ""
                  }
                />
              ) : (
                <Controller
                  name="sipUsername"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      placeholder={t(
                        "Administrator:password_constraints_minimum_length"
                      ).replace("{0}", "8")}
                      label={t("Transmitters:labels_sip_username")}
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      validationError={
                        errors.sipUsername &&
                        (field.value?.length === 0
                          ? t("Errors:input_field_required")
                          : t(
                              "Administrator:password_constraints_minimum_length"
                            ).replace("{0}", "8"))
                      }
                    />
                  )}
                />
              )}
            </>
          )}
          {selectedProduct?.requireSipPassword && (
            <>
              {transmitter ? (
                <TextInput
                  placeholder={t(
                    "Administrator:password_constraints_minimum_length"
                  ).replace("{0}", "8")}
                  label={t("Transmitters:labels_sip_password")}
                  value={sipPassValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 8 && sipErrors.sipPassword) {
                      setSipErrors({
                        ...sipErrors,
                        sipPassword: false,
                      });
                    }
                    setSipPassValue(e.target.value);
                  }}
                  validationError={
                    sipErrors.sipPassword
                      ? t(
                          "Administrator:password_constraints_minimum_length"
                        ).replace("{0}", "8")
                      : ""
                  }
                />
              ) : (
                <Controller
                  name="sipPassword"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      placeholder={t(
                        "Administrator:password_constraints_minimum_length"
                      ).replace("{0}", "8")}
                      type="text"
                      label={t("Transmitters:labels_sip_password")}
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      validationError={
                        errors.sipPassword &&
                        (field.value?.length === 0
                          ? t("Errors:input_field_required")
                          : t(
                              "Administrator:password_constraints_minimum_length"
                            ).replace("{0}", "8"))
                      }
                    />
                  )}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );

  const getWebhookURLShort = () => {
    const urlToCut = transmitter?.webHookUrl ?? "";
    if (urlToCut.slice(0, 47).length < urlToCut.length) {
      return `${urlToCut.slice(0, 47)}...`;
    }
    return urlToCut;
  };

  useEffect(() => {
    if (productCategories !== null) {
      loadFormState();
    }
  }, [productCategories]);

  useEffect(() => {
    setTransmitter();
  }, [transmitter]);

  useEffect(() => {
    if (!credentialsHolder?.length) {
      setCredentialsHolder(credentials);
    }
  }, [credentials.length]);

  useEffect(() => {
    if (credentials.length > 0 && !getValues("callbackTo")) {
      setValue("callbackTo", `sip:${credentials[0].externalReference}`);
    }
  }, []);

  const getPinErrors = (type: string) => {
    switch (type) {
      case "required":
        return t("Transmitters:validation_pin_required");
      case "minLength":
      case "maxLength":
        return t("Errors:pin_min_max_length");
      default:
        break;
    }
  };

  return (
    <>
      <DeleteTransmitteCredentialsModal
        isOpen={isRemoveCredsVisible}
        onClose={() => {
          setIsRemoveCredsVisible(false);
        }}
        transmitterId={transmitter?.id ?? ""}
        credentialsId={credentialsHolder ? credentialsHolder[0]?.id || "" : ""}
        uploadTransmitterData={uploadTransmitterData || undefined}
        cleanCredentialsHolder={() => setCredentialsHolder(undefined)}
      />
      <AddSipCredentialsModal
        onClose={() => {
          setIsAddSipVisible(false);
        }}
        isOpen={isAddSipVisible}
        transmitter={transmitter}
        renderSipInputs={renderSipInputs}
        registerCredentials={registerTransmitterCredentials as any}
      />
      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <form ref={formRef}>
          <Column type="top">
            {transmitter?.productName === "Webhook" && (
              <Row
                type="fill"
                align="fill"
                style={{ marginTop: "16px", alignSelf: "stretch" }}
              >
                <IconWithInfo
                  iconName="link"
                  iconColor="Primary-700"
                  addIconBg
                  bodyComponents={
                    <>
                      <span>{getWebhookURLShort()}</span>
                      <CopyToClipboard text={transmitter.webHookUrl} />
                    </>
                  }
                  headerText={t("Transmitters:webhook")}
                  withHeaderIcon
                  headerIconText={t("Transmitters:webhook_explanation")}
                  headerIconTextHeader={t(
                    "Transmitters:webhook_explanation_header"
                  )}
                />
              </Row>
            )}
            <Row
              type="fill"
              align="fill"
              style={{ marginTop: "16px", alignSelf: "stretch" }}
            >
              {!transmitter && (
                <>
                  <Controller
                    name="productCategoryId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <InputContainer
                        required
                        label={
                          <label htmlFor="addtransmitter_category">
                            {t("Transmitters:create_modal_category")}
                          </label>
                        }
                        input={
                          <Dropdown
                            id="addtransmitter_category"
                            items={categories.current}
                            placeholder={t("Transmitters:select_category")}
                            selectedItem={categories.current?.find(
                              (r) => getValues(field.name) === r.id
                            )}
                            onSelectItem={(item) => {
                              selectCategory(item);
                              setValue("product", undefined);
                              setSelectedProduct(undefined);
                              trigger(field.name);
                            }}
                            invalid={errors.productCategoryId !== undefined}
                            validationError={
                              errors.productCategoryId &&
                              t("Transmitters:validation_productcategory")
                            }
                          />
                        }
                      />
                    )}
                  />
                  <Spacer size={16} />
                </>
              )}
              <Controller
                name="product"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const isCreated = !!transmitter?.id;
                  const hasCategory = !!getValues("productCategoryId");
                  const disabled = isCreated || !hasCategory;

                  return (
                    <InputContainer
                      required
                      label={
                        <label htmlFor="addTransmitter_product">
                          {t("Transmitters:create_modal_product")}
                        </label>
                      }
                      input={
                        disabled ? (
                          <TextInput
                            placeholder={t("Objects:selectproduct")}
                            value={transmitter?.productName}
                            disabled
                          />
                        ) : (
                          <Dropdown
                            id="addTransmitter_product"
                            withFilter
                            maxVisible={10}
                            items={products.current}
                            placeholder={t("Objects:selectproduct")}
                            selectedItem={products.current?.find(
                              (r) => getValues(field.name) === r.id
                            )}
                            onSelectItem={(item) => {
                              selectProduct(item);
                              trigger(field.name);
                            }}
                            invalid={errors.product !== undefined}
                            validationError={
                              errors.product &&
                              t("Transmitters:validation_product")
                            }
                          />
                        )
                      }
                    />
                  );
                }}
              />
            </Row>
            <Spacer size={16} />
            <div className={styles.editFormBlockWrapper}>
              {customerId === undefined && (
                <Controller
                  name="organizationId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputContainer
                      required
                      label={
                        <label htmlFor="addTransmitter_customer">
                          {t("Transmitters:create_modal_customer")}
                        </label>
                      }
                      input={
                        <Tree
                          treeRef={field.ref}
                          id="addTransmitter_customer"
                          placeholder={t("Objects:selectorganisation")}
                          validationError={
                            errors[field.name] &&
                            t("Transmitters:validation_customer")
                          }
                          disabled={
                            organisations === undefined ||
                            organisations.length === 0 ||
                            !!transmitter?.userId
                          }
                          items={organisations}
                          selectedTreeItem={
                            belongsToId ? field.value : undefined
                          }
                          onSelectItem={async (data: ITreeData) => {
                            if (data?.key) {
                              setValue(field.name, +data.key);
                              setBelongsToId(+data.key);
                            } else {
                              setValue(field.name, undefined);
                              setBelongsToId(undefined);
                            }
                            trigger(field.name);
                          }}
                        />
                      }
                    />
                  )}
                />
              )}
              {selectedProduct?.requireIdentifier && (
                <Controller
                  name="identifier"
                  control={control}
                  rules={{
                    required: true,
                    validate: validateIdentifier(
                      selectedProduct.productSpecificIdentifierRegex
                    ),
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      css={{ flex: 1 }}
                      placeholder={
                        selectedProduct.identifierTranslationKey
                          ? t(
                              `Common:validation_${selectedProduct.identifierTranslationKey}`
                            )
                          : undefined
                      }
                      label={t("Common:telephone_identifier")}
                      value={field.value || ""}
                      onChange={field.onChange}
                      validationError={
                        errors.identifier &&
                        getIdentifierErrors(
                          errors.identifier.type,
                          selectedProduct.identifierTranslationKey
                        )
                      }
                      disabled={!!transmitter?.identifier}
                    />
                  )}
                />
              )}
              {selectedProduct?.requireImei && (
                <Controller
                  name="imei"
                  control={control}
                  rules={{
                    required: true,
                    validate: validateImei,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      css={{ flex: 1 }}
                      label={t("Transmitters:labels_imei")}
                      value={field.value || ""}
                      onChange={field.onChange}
                      validationError={
                        errors.imei &&
                        ((errors.imei.type === "required" &&
                          t("Transmitters:validation_imei_required")) ||
                          t("Transmitters:validation_imei_format"))
                      }
                    />
                  )}
                />
              )}
              {transmitter && (
                <>
                  <Controller
                    name="callbackTo"
                    control={control}
                    rules={{
                      validate: (value) =>
                        validateSipUrl(value) ||
                        validatePhoneNumberIfExists(value),
                    }}
                    render={({ field }) => (
                      <TextInput
                        inputRef={field.ref}
                        label={t("Transmitters:callback_outgoing_calls")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        placeholder={t("Transmitters:placeholder_callback")}
                        validationError={
                          errors.callbackTo &&
                          t("Errors:InvalidTelephoneNumberOrSipUriFormat")
                        }
                      />
                    )}
                  />
                  <Controller
                    name="callbackTo2"
                    control={control}
                    rules={{
                      validate: (value) =>
                        validateSipUrl(value) ||
                        validatePhoneNumberIfExists(value),
                    }}
                    render={({ field }) => (
                      <TextInput
                        inputRef={field.ref}
                        label={t("Transmitters:callback_outgoing_calls_2")}
                        value={field.value || ""}
                        onChange={field.onChange}
                        placeholder={t("Transmitters:placeholder_callback")}
                        validationError={
                          errors.callbackTo2 &&
                          t("Errors:InvalidTelephoneNumberOrSipUriFormat")
                        }
                      />
                    )}
                  />
                </>
              )}
              {transmitter?.requirePin && (
                <Controller
                  name="pin"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 5,
                    maxLength: 8,
                  }}
                  render={({ field }) => (
                    <NumberInput
                      required
                      inputRef={field.ref}
                      label={t("Common:pin")}
                      value={field.value?.toString()}
                      onChange={(
                        e: ChangeEvent<HTMLInputElement>,
                        value: string
                      ) => {
                        setValue("pin", value);
                        trigger(field.name);
                      }}
                      validationError={
                        errors.pin && getPinErrors(errors.pin.type)
                      }
                      placeholder={t(
                        "Transmitters:placeholder_transmitter_pin"
                      )}
                    />
                  )}
                />
              )}
            </div>
            <Spacer size={16} />
            <Row type="fill">
              <Controller
                name="model"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Transmitters:model")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Spacer size={16} />
              <Controller
                name="manufacturer"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Transmitters:manufacturer")}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
            <Spacer size={16} />
            <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextArea
                    css={{ flex: 1 }}
                    label={t("Transmitters:labels_comment")}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
            <Row
              type="fill"
              align="fill"
              style={{ marginTop: "16px", alignSelf: "stretch" }}
            >
              <Controller
                name="warrenty"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t("Transmitters:labels_warrenty")}
                    placeholder={dateFormat?.split("/").join("-")}
                    value={field.value}
                    onChange={field.onChange}
                    dateFormat={dateFormat?.split("/").join("-")}
                  />
                )}
              />
              <Spacer size={16} />
              <Controller
                name="leasingExpires"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label={t("Transmitters:labels_leasingexpires")}
                    placeholder={dateFormat?.split("/").join("-")}
                    value={field.value}
                    onChange={field.onChange}
                    dateFormat={dateFormat?.split("/").join("-")}
                  />
                )}
              />
            </Row>
            <Row
              type="fill"
              align="fill"
              style={{ marginTop: "16px", alignSelf: "stretch" }}
            >
              <Controller
                name="serialNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    dataCy="serial_number"
                    label={t("Transmitters:labels_serialnumber")}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
              <Spacer size={16} />
              <Controller
                name="version"
                control={control}
                render={({ field }) => (
                  <TextInput
                    type="text"
                    label={t("Transmitters:labels_version")}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
            <Row
              type="fill"
              align="fill"
              style={{ marginTop: "16px", alignSelf: "stretch" }}
            >
              <Controller
                name="missing"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("Transmitters:labels_missing")}
                    checked={field.value}
                    onChange={field.onChange}
                    style={{ paddingLeft: 0 }}
                  />
                )}
              />
            </Row>
            {showBroken && (
              <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
                <Controller
                  name="broken"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("Transmitters:labels_broken")}
                      checked={field.value}
                      onChange={field.onChange}
                      style={{ paddingLeft: 0 }}
                    />
                  )}
                />
              </Row>
            )}
            {!transmitter && (
              <>
                <Spacer size={8} />
                <Row type="left" align="fill">
                  <Checkbox
                    label={t("Common:create_more")}
                    description={t("Common:create_more_description")}
                    checked={createMore}
                    onChange={(e) => setCreateMore(e.target.checked)}
                    style={{ paddingLeft: 0 }}
                  />
                </Row>
              </>
            )}
          </Column>

          <Spacer size={16} />

          {dataLoading &&
            (transmitter?.requireSipUsername ||
              transmitter?.requireSipPassword) && (
              <>
                <Row type="left" align="start" style={{ width: "100%" }}>
                  <LoadingSpinner theme="primary" size={32} margin="unset" />
                </Row>
                <Spacer size={24} />
              </>
            )}

          {!dataLoading && !!credentialsHolder?.length && (
            <>
              <h2>{t("Transmitters:labels_sip_credentials")}</h2>
              <Spacer size={16} />
              {credentialsHolder.map((item) => (
                <>
                  <div key={item.id} className={styles.editFormBlockWrapper}>
                    <TextInput
                      key={`input_${item.id}`}
                      value={item.username}
                      renderIcon={() => (
                        <Icon
                          key={`icon_${item.id}`}
                          name="x"
                          size={16}
                          onClick={() => setIsRemoveCredsVisible(true)}
                        />
                      )}
                      iconPosition="right"
                      disabled
                      width="100%"
                    />
                  </div>
                  {credentialsHolder.length > 1 && (
                    <Spacer key={`spacer_${item.id}`} size={8} />
                  )}
                </>
              ))}
            </>
          )}

          {!credentialsHolder?.length &&
            (!!transmitter?.requireSipPassword ||
              !!transmitter?.requireSipUsername) && (
              <div style={{ marginTop: "8px" }}>
                <Button
                  text={t("Transmitters:labels_add_credentials")}
                  onClick={() => setIsAddSipVisible(true)}
                  variant="border-link"
                  image="plus"
                  iconVariant="primary"
                  disabled={isCredentialsLoading || dataLoading}
                  loading={isCredentialsLoading || dataLoading}
                />
              </div>
            )}

          {!!transmitter?.sipCredentialType &&
            !credentialsHolder?.length &&
            !transmitter?.requireSipPassword &&
            !transmitter?.requireSipUsername && (
              <>
                <h2>{t("Transmitters:labels_sip_credentials")}</h2>
                <Spacer size={16} />
                <Button
                  image="refresh"
                  variant="border-link"
                  text={t("Transmitters:generate_credentials")}
                  onClick={() =>
                    registerTransmitterCredentials(transmitter?.id)
                  }
                  loading={isCredentialsLoading || dataLoading}
                  disabled={isCredentialsLoading || dataLoading}
                />
                <Spacer size={16} />
              </>
            )}

          {!transmitter &&
            (selectedProduct?.requireSipPassword ||
              selectedProduct?.requireSipUsername) &&
            renderSipInputs()}
        </form>
      )}
    </>
  );
});
