import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../Layout/Layout";
import {
  addTransmitterToAdmin,
  addTransmitterToObject,
  getPositioningTransmitters,
  getTransmittersInStock,
} from "../../core/api/transmitters/transmitters";
import useUser from "../../core/user/useUser";
import Table from "../../ui-lib/components/Tables/Table";
import TableCell, {
  MissingValueTableCell,
} from "../../ui-lib/components/Tables/TableCell";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import { Transmitter } from "../../core/api/transmitters/types";
import { DialogActions, EditTransmitter } from "./EditTransmitter";
import {
  formattedDateString,
  notifyApiErrors,
  sortParamsToString,
} from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import Radio from "../../ui-lib/components/Radio/Radio";
import Button from "../../ui-lib/components/Button/Button";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";

export const AddAlarmTransmitter = ({
  onSubmit,
  onClose,
  customerId,
  objectId,
  adminId,
  adminPositioningTransmitter,
  isAddTransmitterOpen,
  setIsAddTransmitterOpen,
}: {
  isAddTransmitterOpen: boolean;
  setIsAddTransmitterOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit?: () => void;
  onClose?: () => void;
  customerId?: number;
  objectId?: string;
  adminId?: number;
  adminPositioningTransmitter?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transmitters, setTransmitters] = useState<Transmitter[]>();
  const [search, setSearch] = useState<string | undefined>();
  const [savedAdminId, setSavedAdminId] = useState<number | undefined>(adminId);
  const [selectedTransmitterId, setSelectedTransmitterId] = useState<
    number | undefined
  >();
  const { sortParams, setSortParams } = useSortParams(search);

  const active = useRef(false);
  const editTransmitterRef = useRef<DialogActions>(null);

  const closeModal = () => {
    setIsAddTransmitterOpen(false);
    setIsSubmitting(false);
    setSelectedTransmitterId(undefined);
    onClose?.();
  };

  useEffect(() => {
    // need this to be able to add transmitter to admin
    // after sort adminId is lost
    if (adminId && !savedAdminId) {
      setSavedAdminId(adminId);
    }
  }, [adminId]);

  async function loadData() {
    setIsSearching(true);
    try {
      if (active.current) {
        if (adminPositioningTransmitter) {
          const data = await getPositioningTransmitters(
            {
              page: 1,
              pageSize: 200,
              order: sortParamsToString(sortParams),
              query: search ?? "",
              supportadmins: adminPositioningTransmitter,
            },
            authenticatedRequest
          );
          setTransmitters(data);
        } else {
          const { result } = await getTransmittersInStock(
            {
              page: 1,
              pageSize: 200,
              order: sortParamsToString(sortParams),
              query: search ?? "",
            },
            authenticatedRequest
          );
          setTransmitters(result);
        }
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsSearching(false);
    }
  }
  const onSave = async (createMore?: boolean, transmitterId?: number) => {
    setIsSubmitting(true);
    const idToUse = selectedTransmitterId ?? transmitterId;
    if (objectId && idToUse) {
      try {
        await addTransmitterToObject(objectId, idToUse, authenticatedRequest);
        onSubmit?.();
        if (!createMore) {
          closeModal();
        }
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    } else if (!!savedAdminId && !!selectedTransmitterId) {
      try {
        await addTransmitterToAdmin(
          savedAdminId,
          selectedTransmitterId,
          authenticatedRequest
        );
        onSubmit?.();
        closeModal();
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    } else {
      console.error(
        `Could not add transmitter, given values: ${adminId}, ${objectId},${selectedTransmitterId}
        }`
      );
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (isAddTransmitterOpen) loadData();
  }, [isAddTransmitterOpen]);
  useEffect(() => {
    loadData();
  }, [search, sortParams]);
  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Objects:transmitter_modal_title")}
      isOpen={isAddTransmitterOpen}
      onClose={closeModal}
      contentStyle={{ width: "1000px" }}
      submitBtn={{
        text: t("Common:save"),
        variant: "primary",
        onClick: onSave,
        disabled: !selectedTransmitterId,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: closeModal }}
      loading={isSubmitting}
    >
      <Spacer size={16} />
      <Column type="top" align="start">
        <p>{t("Objects:transmitter_modal_description")}</p>
        <Spacer size={16} />
        <SearchInput
          placeholder={t("Transmitters:search_placeholder")}
          value={search ?? ""}
          onChange={setSearch}
        />
        <Spacer size={16} />
        <Row type="space" style={{ alignSelf: "stretch" }}>
          <h2>{t("Transmitters:in_stock")}</h2>

          {!adminPositioningTransmitter && (
            <>
              <Button
                variant="secondary"
                image="status-online"
                text={t("Transmitters:add_new_transmitter")}
                onClick={() => {
                  editTransmitterRef.current?.open();
                }}
              />
              <EditTransmitter
                ref={editTransmitterRef}
                modalTitle={t("Transmitters:add_new_transmitter")}
                saveButtonTitle={t("Transmitters:add_new_transmitter")}
                customerId={customerId}
                onSubmit={() => {
                  loadData();
                }}
                onStockTransmitterSave={onSave}
                toObject
              />
            </>
          )}
        </Row>
        <Spacer size={16} />
        {isSearching ? (
          <LoadingSpinner theme="primary" />
        ) : (
          <Table<Transmitter>
            columns={[
              {
                key: "productName",
                header: t("Objects:accessories_table_name"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.productName} />
                ),
                sortByField: "product",
              },
              {
                key: "model",
                header: t("Objects:accessories_table_model"),
                fieldTemplate: (rowData) => (
                  <MissingValueTableCell
                    value={rowData.model}
                    iconColor="Grey-300"
                    missingValueText={t("Transmitters:no_model")}
                    leftIcon="clipboard-list"
                  />
                ),
              },
              {
                key: "identifier",
                header: t("Objects:Identifiertranslations_Identifier"),
                fieldTemplate: (rowData) => (
                  <TableCell
                    value={rowData.identifier}
                    iconColor="Grey-300"
                    leftIcon="phone"
                    className="cellContentEllipsis"
                  />
                ),
                sortByField: "phone",
              },
              {
                key: "customerName",
                header: t("Common:belongs_to"),
                fieldTemplate: (rowData) => (
                  <TableCell value={rowData.customerName} />
                ),
              },
              {
                key: "lastCommunicationTest",
                header: t("Transmitters:table_columns_lastCommunicationTest"),
                fieldTemplate: (rowData) => (
                  <MissingValueTableCell
                    iconColor="Grey-300"
                    value={formattedDateString(rowData.lastCommunicationTest)}
                    missingValueText={t("Transmitters:no_last_communication")}
                    leftIcon="clock"
                  />
                ),
                sortByField: "lastcommn",
              },
              {
                editable: true,
                style: { minWidth: "50px", width: "50px" },
                fieldTemplate: (rowData) => (
                  <Radio
                    name={`${rowData.id}`}
                    value={rowData.id}
                    checked={rowData.id === selectedTransmitterId}
                    innerStyle={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onChange={() => {
                      setSelectedTransmitterId(
                        selectedTransmitterId === rowData.id
                          ? undefined
                          : rowData.id
                      );
                    }}
                  />
                ),
              },
            ]}
            items={transmitters}
            hideEmptyMessage
            noRowsMessage={
              search
                ? t("Transmitters:transmitters_table_no_match")
                : t("Transmitters:transmitters_table_not_instock")
            }
            sortParams={sortParams}
            onSortChange={(sortData) => {
              setSortParams?.(sortData);
            }}
            showRowHover
            withShowMore
          />
        )}
      </Column>
    </PrimeModal>
  );
};
