import { ChangeEvent, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import { TextInput } from "./TextInput";

type OnChangeFunction = (
  e: ChangeEvent<HTMLInputElement>,
  value: string
) => void;

export interface NumberInputProps {
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  value?: string;
  onChange?: OnChangeFunction;
  pinnedText?: string;
  pinnedTextPosition?: "left" | "right";
  min?: number;
  max?: number;
  step?: number;
  enableNumberArrows?: boolean;
  id?: string;
  width?: string | number;
}

/** Text input for numbers.
 * NB: Input value provided as string. */
export const NumberInput = ({
  inputRef,
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  value = "",
  onChange = () => {},
  pinnedText = undefined,
  pinnedTextPosition = undefined,
  min = undefined,
  max = undefined,
  step = undefined,
  enableNumberArrows = false,
  id,
  width,
}: NumberInputProps) => (
  <TextInput
    inputRef={inputRef}
    type="number"
    label={label}
    id={id}
    hint={hint}
    placeholder={placeholder}
    required={required}
    disabled={disabled}
    description={description}
    validationError={validationError}
    value={value}
    onChange={(e, enteredValue) => {
      // validate min/max
      if (enteredValue !== "" && min !== undefined) {
        enteredValue = Math.max(+enteredValue, min).toString();
      }
      if (enteredValue !== "" && max !== undefined) {
        enteredValue = Math.min(+enteredValue, max).toString();
      }
      onChange(e, enteredValue);
    }}
    pinnedText={pinnedText}
    pinnedTextPosition={pinnedTextPosition}
    min={min}
    max={max}
    step={step}
    enableNumberArrows={enableNumberArrows}
    width={width}
  />
);
export default NumberInput;
