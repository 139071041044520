import { useState, ChangeEventHandler, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import { TextInput } from "./TextInput";
import Icon from "../Icon";

export interface PasswordInputProps {
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  value?: string;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

/** Text input for passwords */
export const PasswordInput = ({
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  value = "",
  inputRef,
  onChange = () => {},
}: PasswordInputProps) => {
  const [type, setType] = useState<"password" | "text">("password");

  return (
    <TextInput
      inputRef={inputRef}
      type={type}
      label={label}
      hint={hint}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      description={description}
      validationError={validationError}
      value={value}
      onChange={onChange}
      iconLabel={type === "password" ? "show password" : "hide password"}
      renderIcon={() =>
        type === "password" ? (
          <Icon name="eye" size={24} />
        ) : (
          <Icon name="eye-off" size={24} />
        )
      }
      iconPosition="right"
      onIconClick={
        disabled
          ? undefined
          : () => {
              type === "password" ? setType("text") : setType("password");
            }
      }
    />
  );
};
export default PasswordInput;
