import { weekdaysShort } from "moment";

export const userDateFormat = (date: string) => date.split("/").join("-");

export const normalizeDate = (dateStr: string): string => {
  const [year, month, day] = dateStr.split("-").map(Number);
  const normalizedMonth = month.toString().padStart(2, "0");
  const normalizedDay = day.toString().padStart(2, "0");
  return `${year}-${normalizedMonth}-${normalizedDay}`;
};

export const getWeekDays = () => {
  const weekdays = weekdaysShort(false);

  return [...weekdays.slice(1), weekdays[0]];
};
