import { CSSProperties } from "react";
import Checkbox, { ICheckbox } from "../Checkbox/Checkbox";
import AccordionBase from "./Accordion";
import styles from "./Accordion.module.css";
import useTranslations from "../../../core/i18n/useTranslations";

export interface AccordionProps {
  title: string;
  description: string;
  icon: string;
  items: ICheckbox[];
  onCheck(arg: ICheckbox[], unselectAll?: boolean): void;
  selectedItemsNum: number;
  containerStyle?: CSSProperties;
  isEditable?: boolean;
  accordionKey?: string;
}

export default function CheckboxListAccordion({
  title,
  description,
  icon,
  items,
  onCheck,
  selectedItemsNum,
  containerStyle,
  isEditable = true,
  accordionKey,
}: Readonly<AccordionProps>) {
  const t = useTranslations();
  const details = `${selectedItemsNum}/${items.length}`;

  return (
    <AccordionBase
      title={title}
      description={description}
      icon={icon}
      details={details}
      bodyClassName={styles.tintedBody}
      containerStyle={containerStyle}
      accordionKey={accordionKey}
    >
      {isEditable && (
        <Checkbox
          checked={selectedItemsNum === items.length}
          label={t(
            `Common:${
              selectedItemsNum === items.length ? "de" : ""
            }select_all_below`
          )}
          onChange={() => {
            if (selectedItemsNum === items.length) {
              onCheck(items, true);
            } else {
              const toSelect = [] as ICheckbox[];
              items.forEach((item) => {
                if (!item.checked) {
                  toSelect.push(item);
                }
              });
              onCheck(toSelect);
            }
          }}
          key={"selectAll"}
          fullWidth
          style={{ marginBottom: "16px" }}
        />
      )}
      {items.map((item) => (
        <Checkbox
          label={item.label}
          description={item.description}
          checked={item.checked}
          onChange={() => onCheck([item])}
          fullWidth
          style={{ marginBottom: "16px" }}
          key={item.id}
          disabled={!isEditable}
        />
      ))}
    </AccordionBase>
  );
}
