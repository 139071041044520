import queryString from "query-string";
import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  CommonContactResponse,
  ContactResponse,
  ContactDetails,
  IContactsReorder,
  IScheduleItem,
  IContactsRelation,
  ICommonContacts,
  CheckContactExists,
  IAssociatedObjects,
} from "./types";

const useCommonContacts = (params: {
  search?: string;
  page?: number;
  pageSize?: number;
  organizationIds?: string;
}) => {
  const settings = queryString.stringify({
    ...params,
  });

  return useAuthenticatedFetch<CommonContactResponse[]>(
    `${API_HOST}api/v2/contacts?${settings}`
  );
};

const useCommonContactsForUser = (
  userId: string,
  params: { page: number; pageSize: number; search: string }
) => {
  const settings = queryString.stringify({
    contactType: 2,
    ...params,
  });

  return useAuthenticatedFetch<ContactResponse[]>(
    `${API_HOST}api/v2/users/${userId}/contacts?${settings}`
  );
};

const useContactDetails = (contactId: number, objectId?: string) => {
  const settings = queryString.stringify({
    userId: objectId,
  });

  return useAuthenticatedFetch<ContactDetails>(
    `${API_HOST}api/v2/contacts/${contactId}?${settings}`
  );
};

const useUserContactDetails = (contactId: number, objectId?: string) =>
  useAuthenticatedFetch<ContactDetails>(
    `${API_HOST}api/v2/users/${objectId}/contacts/${contactId}`
  );

const getCommonContacts = async (
  params: {
    search?: string;
    page?: number;
    pageSize?: number;
    organizationIds?: string;
  },
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify({
    ...params,
  });

  return authenticatedRequest.get<ICommonContacts>(
    `${API_HOST}api/v2/contacts/table?${settings}`
  );
};

const getContactDetails = async (
  id: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ContactDetails>(`${API_HOST}api/v2/contacts/${id}`);

const getUserContacts = async (
  objectId: string,
  authenticatedRequest: AxiosInstance,
  page?: number
) =>
  authenticatedRequest.get<ContactResponse[]>(
    `${API_HOST}api/v2/users/${objectId}/contacts?contactType=1${
      page ? `&page=${page}&pageSize=15` : ""
    }`
  );

const editContact = async (
  {
    contactId,
    contactDetails,
  }: {
    contactId: number;
    contactDetails: ContactDetails;
  },
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(contactDetails);

  return authenticatedRequest.put<{}>(
    `${API_HOST}api/v2/contacts/${contactId}`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const createPrivateContact = async (
  contactDetails: ContactDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(contactDetails);

  return authenticatedRequest.post<any>(
    `${API_HOST}api/v2/contacts`,
    requestData,
    { headers: { "Content-Type": "application/json" } }
  );
};

const deletePrivateContact = async (
  objectId: string,
  contactId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(
    `${API_HOST}api/v2/users/${objectId}/contacts/${contactId}`
  );

const associateCommonContact = (
  objectId: string,
  contactId: number,
  values: IContactsRelation,
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify(values);
  return authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/users/${objectId}/contacts/${contactId}/associate?${settings}`
  );
};

const updateAssociationCommonContact = (
  objectId: string,
  contactId: number,
  values: IContactsRelation,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<{}>(
    `${API_HOST}api/v2/users/${objectId}/contacts/${contactId}/associate?`,
    values
  );

const unassociateCommonContact = (
  objectId: string,
  contactId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete<{}>(
    `${API_HOST}api/v2/users/${objectId}/contacts/${contactId}/associate`
  );

const createCommonContact = async (
  contactDetails: ContactDetails,
  authenticatedRequest: AxiosInstance
) => {
  const requestData = JSON.stringify(contactDetails);

  return authenticatedRequest.post<any>(
    `${API_HOST}api/v2/contacts`,
    requestData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const checkCommonContactExists = async (
  exists: CheckContactExists,
  authenticatedRequest: AxiosInstance
) => {
  const settings = queryString.stringify(exists);

  return authenticatedRequest.get<CheckContactExists>(
    `${API_HOST}api/v2/contacts/exists?${settings}`
  );
};

const deleteCommonContact = (
  contactId: number,
  authenticatedRequest: AxiosInstance
) => authenticatedRequest.delete<{}>(`${API_HOST}api/v2/contacts/${contactId}`);

const createContactCredentails = (
  contactId: number,
  authenticatedRequest: AxiosInstance,
  isPrivate?: boolean,
  userId?: string
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/contacts/${contactId}/resource${
      userId && isPrivate ? `?userId=${userId}` : ""
    }`
  );

const reorderContactsPriotiry = (
  objectId: string,
  contacts: IContactsReorder,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/users/${objectId}/contacts/order`,
    JSON.stringify({ contactPriorities: contacts }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getScheduleItems = (
  contactId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IScheduleItem[]>(
    `${API_HOST}api/v2/contacts/${contactId}/scheduleitems`
  );

const updateScheduleItem = (
  contactId: number,
  values: IScheduleItem,
  authenticatedRequest: AxiosInstance
) => {
  const methodType = values.id ? "put" : "post";
  return authenticatedRequest[methodType](
    `${API_HOST}api/v2/contacts/${contactId}/scheduleitem`,
    JSON.stringify(values),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const deleteScheduleItem = (
  contactId: number,
  scheduleItemId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(
    `${API_HOST}api/v2/contacts/${contactId}/scheduleitem/${scheduleItemId}`
  );

const fetchAssociatedObjects = (
  contactId: number,
  authenticatedRequest: AxiosInstance,
  params: {
    page: number;
    pageSize: number;
  }
) => {
  const settings = queryString.stringify({
    ...params,
  });
  return authenticatedRequest.get<IAssociatedObjects>(
    `${API_HOST}api/v2/contacts/${contactId}/users/table?${settings}`
  );
};

export {
  useCommonContacts,
  useCommonContactsForUser,
  useContactDetails,
  getUserContacts,
  getCommonContacts,
  getContactDetails,
  editContact,
  createPrivateContact,
  deletePrivateContact,
  associateCommonContact as addCommonContact,
  updateAssociationCommonContact,
  unassociateCommonContact,
  createCommonContact,
  deleteCommonContact,
  createContactCredentails,
  useUserContactDetails,
  reorderContactsPriotiry,
  getScheduleItems,
  updateScheduleItem,
  deleteScheduleItem,
  checkCommonContactExists,
  fetchAssociatedObjects,
};
