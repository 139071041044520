import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { Row, Spacer } from "../../../components/Layout/Layout";
import {
  AlarmRuleResponse,
  deleteAlarmCodeRule,
  getAlarmRule,
  saveAlarmCode,
} from "../../../core/api/alarmCodeRule";
import {
  useAlarmAttachments,
  useAlarmDetails,
} from "../../../core/api/alarmDetail";
import useTranslations from "../../../core/i18n/useTranslations";
import useUser from "../../../core/user/useUser";
import Button from "../../../ui-lib/components/Button/Button";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { ExtraInfoDotList } from "../../../ui-lib/components/ExtraInfoDotList/ExtraInfoDotList";
import { IconWithInfo } from "../../../ui-lib/components/IconsWithInfo/IconWithInfo";
import Information from "../../../ui-lib/components/Information/Information";
import ListWithDot from "../../../ui-lib/components/ListWithDot/ListWithDot";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal/Modal";
import Tabs from "../../../ui-lib/components/Tabs/Tabs";
import styles from "./AlarmHistoryDetails.module.css";
import { CodeOverrideModal } from "./CodeOverrideModal/CodeOverrideModal";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import {
  getInterpolatedError,
  isSipUsername,
  notifyApiErrors,
  safeGetFromArray,
} from "../../../core/helpers/helpers";
import { AdditionalClassificationModal } from "./AdditionalClassificationModal";
import Icon from "../../../ui-lib/components/Icon";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { CopyToClipboard } from "../../../ui-lib/components/CopyToClipboard/CopyToClipboard";
import AttachmentsTable from "./AttachmentsTable";
import {
  forceDeactivateAlarmCall,
  gatherAlarmDebugInfoCall,
} from "../../../core/api/alarms";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";

export const AlarmHistoryDetails = ({ id }: { id: string }) => {
  const t = useTranslations();
  const { formatDateTime } = DateTimeHelper;
  const { authenticatedRequest, config } = useUser();
  const codeRulesEnabled = config?.show.includes("AlarmCodeRuleUser");
  const { data, isLoading } = useAlarmDetails(id);
  const { data: attachmentData, isLoading: isAttachmentLoading } =
    useAlarmAttachments(id);
  const user = useUser();
  const [currentTabId, setCurrentTabId] = useState(0);
  const [ruleData, setRuleData] = useState<AlarmRuleResponse>();
  const [rulesError, setRulesError] = useState("");
  const [showCodeOverrideModal, setShowCodeOverrideModal] = useState(false);
  const [isCodeOverrideLoading, setIsCodeOverrideLoading] = useState(false);
  const [disableCodeOverrideButtons, setCodeOverrideButtonsState] =
    useState(false);
  const [classificationModalOpen, setClassificationModalOpen] = useState(false);
  const [newAlarmCode, setNewAlarmCode] = useState<number | string>();
  const [logLink, setLogLink] = useState<string>();

  const playerPermission = config?.show.includes("PlayCallRecord");
  const downloadAttachmentPermission = config?.show.some((item) =>
    ["AlarmAttachement", "AlarmAttachementCreate", "AlarmAttachment"].includes(
      item
    )
  );

  const tabsData = [
    {
      isSelected: currentTabId === 0,
      name: t("AlarmHistory:details_page_tab_alarm_log"),
      id: 0,
    },
    {
      isSelected: currentTabId === 1,
      name: t("AlarmHistory:details_page_tab_alarm_advanced"),
      id: 1,
    },
    {
      isSelected: currentTabId === 2,
      name: t("AlarmHistory:attachments"),
      id: 2,
    },
  ];

  const fetchRulesData = async () => {
    try {
      setIsCodeOverrideLoading(true);
      const result = await getAlarmRule(id, authenticatedRequest);
      setRuleData(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
      setRulesError(
        getInterpolatedError("Errors", error.response?.data?.errors)
      );
    } finally {
      setIsCodeOverrideLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setLogLink(
        `https://my.eu-01.cloud.solarwinds.com/209368664143117312/logs?q=(${data.password}+OR+${data.globalAlarmId})+-IIS`
      );
    }
  }, [data]);

  useEffect(() => {
    if (ruleData) {
      setNewAlarmCode(ruleData.currentAlarmCode || "");
    }
  }, [ruleData]);

  useEffect(() => {
    if (codeRulesEnabled) {
      fetchRulesData();
    }
  }, []);

  const getValidationError = () => {
    if (
      newAlarmCode &&
      (`${newAlarmCode}`.length < 3 || `${newAlarmCode}`.length > 5)
    ) {
      setCodeOverrideButtonsState(true);
      return t("Errors:incorrect_3-5_length");
    }
    if (disableCodeOverrideButtons) {
      setCodeOverrideButtonsState(false);
    }
    return undefined;
  };

  const processOverrideButtons = async (responseCenterRule = false) => {
    try {
      setIsCodeOverrideLoading(true);
      await saveAlarmCode(
        id,
        newAlarmCode!,
        responseCenterRule,
        user.authenticatedRequest
      );
      setShowCodeOverrideModal(false);
      notify({
        message: t("AlarmHistory:code_override_success"),
      });
      fetchRulesData();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsCodeOverrideLoading(false);
    }
  };

  const renderAlarmBaseInfo = () => (
    <>
      <a
        className={styles.objectLink}
        href={`/adminportal/objects/${data?.userId}`}
      >
        {data?.name}
      </a>
      <span className={styles.phoneWrapper}>({data?.phone})</span>
    </>
  );

  const splitLogText = (logText: string): string[] => {
    const strArr = logText.split(":");

    // Need to handle the following logTexts:
    // 'Client commanded to call to sip:username'
    // 'Incoming voice call with id:callsid from:sip:username'

    if (isSipUsername(`${strArr[0]}:${strArr[1]}`)) {
      // 'sip:username'
      strArr[0] = `${strArr[0]}:${strArr[1]}`;
      strArr.splice(1, 1);
    } else if (isSipUsername(`${strArr[1]}:${strArr[2]}`)) {
      // 'callsid:sip:username'
      strArr[1] = `${strArr[1]}:${strArr[2]}`;
      strArr.splice(2, 1);
    }
    return strArr;
  };

  const details = (logType: number, logText: string) => {
    if (logType === 2) {
      return [logText];
    }
    if (logType !== 5) {
      return splitLogText(logText);
    }
    return logText.split("\n");
  };

  const format = (str: string, value_0 = "", value_1 = "", value_2 = "") => {
    let formatted = str;
    formatted = formatted.replace(/\{0}/gi, value_0);
    formatted = formatted.replace(/\{1}/gi, value_1);
    formatted = formatted.replace(/\{2}/gi, value_2);
    return formatted;
  };

  const renderLogs = () => {
    const items = data?.log?.map((log) => {
      const detailsItem = details(log.type, log.text);
      const logText = t(`LogEvent:LogEvent_${log.type}_${log.subType}`);
      return {
        centralData: logText
          ? format(
              logText,
              safeGetFromArray(0, detailsItem),
              safeGetFromArray(1, detailsItem),
              safeGetFromArray(2, detailsItem)
            )
          : format(
              t("LogEvent:LogEventUndefined"),
              log.type.toString(),
              log.subType.toString(),
              log.text
            ),
        leftData: formatDateTime(log.created, "HH:mm:ss, D MMM YYYY"),
        rightData: log.adminName,
      };
    });

    return (
      items && <ExtraInfoDotList items={items.reverse()} whichToHighlight={0} />
    );
  };

  const deleteAlarmCode = async () => {
    try {
      setIsCodeOverrideLoading(true);
      await deleteAlarmCodeRule(id, authenticatedRequest);
      await fetchRulesData();
      notify({
        message: t("AlarmHistory:code_override_deleted"),
      });
      setShowCodeOverrideModal(false);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsCodeOverrideLoading(false);
    }
  };

  const renderDetailsHeader = (alarmCode: number) => (
    <>
      <h3>
        {data.alarmCodeDescription ?? t(`CommonEnum:AlarmCode${alarmCode}`)} (
        <span>{alarmCode}</span>)
      </h3>
    </>
  );

  const isSaveDisabled =
    isCodeOverrideLoading || disableCodeOverrideButtons || !newAlarmCode;

  const renderOverrideModalFooter = (
    <div className={styles.codeOverrideFooter}>
      <Button
        type="button"
        variant="primary"
        text={t("AlarmHistory:save_for_object")}
        onClick={() => processOverrideButtons()}
        disabled={isSaveDisabled}
      />

      <Button
        type="button"
        variant="secondary"
        text={t("AlarmHistory:save_for_objects_with_code")}
        onClick={() => processOverrideButtons(true)}
        disabled={isSaveDisabled}
      />

      {ruleData?.currentAlarmCode && (
        <Button
          variant="destructive"
          text={t("Common:delete")}
          onClick={deleteAlarmCode}
          disabled={isCodeOverrideLoading}
        />
      )}

      <Button
        type="button"
        variant="secondary"
        text={t("Common:cancel")}
        onClick={() => setShowCodeOverrideModal(false)}
        disabled={isCodeOverrideLoading}
      />
    </div>
  );

  const forceDeactivateAlarm = async () => {
    try {
      await forceDeactivateAlarmCall(id, authenticatedRequest);
      notify({
        message: t("Common:background_job_launched"),
        variant: "info",
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const gatherAlarmDebugInfo = async () => {
    try {
      await gatherAlarmDebugInfoCall(id, authenticatedRequest);
      notify({
        message: t("Common:background_job_launched"),
        variant: "info",
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const renderAdditionalInfo = () => {
    const classificationItems = data?.flags.length
      ? data.flags.map(
          (flag) => `${t(`CommonEnum:AlarmFlagEnum${flag}`)} (${flag})`
        )
      : null;
    return (
      <>
        <Row type="left">
          {config?.show.includes("RcAdvancedSettings") &&
            data.alarmPageLink && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  style={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                  }}
                  target="_blank"
                  href={data.alarmPageLink}
                  rel="noreferrer"
                >
                  {t("AlarmHistory:alarm_page_link")}
                </a>
                <Spacer size={8} />
                <Icon name="external-link" color="Primary-700" />
              </div>
            )}
          {config?.show.includes("ForceDeactivateAlarm") && (
            <>
              {config?.show.includes("RcAdvancedSettings") && (
                <Spacer size={16} />
              )}
              <Button
                variant="secondary"
                text={t("AlarmHistory:force_deactivate_alarm")}
                onClick={forceDeactivateAlarm}
              />
            </>
          )}
          {config?.show.includes("GatherAlarmDebugInfo") && (
            <>
              {(config?.show.includes("RcAdvancedSettings") ||
                config?.show.includes("ForceDeactivateAlarm")) && (
                <Spacer size={16} />
              )}
              <Button
                variant="secondary"
                text={t("AlarmHistory:gather_alarm_debug_info")}
                onClick={gatherAlarmDebugInfo}
              />
            </>
          )}
        </Row>
        {(config?.show.includes("RcAdvancedSettings") ||
          config?.show.includes("ForceDeactivateAlarm") ||
          config?.show.includes("GatherAlarmDebugInfo")) && (
          <Spacer size={24} />
        )}
        <Row type="space">
          <IconWithInfo
            iconName="hashtag"
            iconColor="Primary-700"
            bodyComponents={renderDetailsHeader(data?.alarmCode || 0)}
          />
          {data.canBeClassified && (
            <Button
              image="cog"
              variant="link"
              text={t("AlarmHistory:config_additional_classification")}
              onClick={() => {
                setClassificationModalOpen(true);
              }}
            />
          )}
        </Row>

        <Spacer size={8} />
        {classificationItems && (
          <>
            <p className={styles.classificationHeader}>
              {t("AlarmHistory:consists_of_classifications")} (
              {data?.flags.length})
            </p>
            <ListWithDot items={classificationItems} />
          </>
        )}

        <Spacer size={8} />
        {codeRulesEnabled && (
          <Button
            variant="secondary"
            text={t("AlarmHistory:alarm_code_override_create")}
            onClick={() => setShowCodeOverrideModal(true)}
          />
        )}
        {ruleData && showCodeOverrideModal && (
          <PrimeModal
            withHeader
            isOpen={showCodeOverrideModal}
            onClose={() => {
              setShowCodeOverrideModal(false);
            }}
            header={t("AlarmHistory:alarm_code_override")}
            customFooter={renderOverrideModalFooter}
          >
            <CodeOverrideModal
              ruleData={ruleData}
              rulesError={rulesError}
              newAlarmCode={newAlarmCode}
              setNewAlarmCode={setNewAlarmCode}
              disableButtons={setCodeOverrideButtonsState}
              getValidationError={getValidationError}
            />
          </PrimeModal>
        )}
      </>
    );
  };

  const renderCurrentTab = () => {
    let currentTab: ReactNode;
    switch (currentTabId) {
      case 0:
        currentTab = renderLogs();
        break;
      case 1:
        currentTab = renderAdditionalInfo();
        break;
      case 2:
        currentTab = (
          <AttachmentsTable
            playerPermission={playerPermission}
            downloadAttachmentPermission={downloadAttachmentPermission}
            tableData={attachmentData}
            authReq={authenticatedRequest}
            id={id}
          />
        );
        break;
      default:
        break;
    }
    return (
      <>
        <Spacer size={8} />
        <Divider />
        <Spacer size={24} />
        <div>{currentTab}</div>
      </>
    );
  };

  const calculateHandlingTime = () => {
    const milliseconds = moment(data?.deactivationTime).diff(
      moment(data?.serverAlarmTime)
    );
    const duration = moment.duration(milliseconds);
    const days = duration.days();
    return `${
      days > 0 ? `${days}${t("AlarmHistory:days_shortcut")} ` : ""
    }${duration.hours()}${t(
      "AlarmHistory:hour_shortcut"
    )} ${duration.minutes()}${t(
      "AlarmHistory:minute_shortcut"
    )} ${duration.seconds()}${t("AlarmHistory:seconds_shortcut")}`;
  };

  const renderModalBodyContent = () =>
    data && (
      <>
        {data.canBeClassified && (
          <Modal
            isOpen={classificationModalOpen}
            onClose={() => setClassificationModalOpen(false)}
            modalTitle={t("AlarmHistory:classification_modal_title")}
            width="576px"
            closeOnDocumentClick={false}
            hideFooter
          >
            <AdditionalClassificationModal
              alarmId={id}
              onClose={() => setClassificationModalOpen(false)}
            />
          </Modal>
        )}

        <div>
          <div>
            <IconWithInfo
              iconName="cube"
              iconColor="Primary-700"
              bodyComponents={renderAlarmBaseInfo()}
            />
          </div>
          <p>
            {t("AlarmHistory:triggered_alarm_at")
              .replace(
                "{Alarm}",
                `${
                  data.alarmCodeDescription ??
                  t(`CommonEnum:AlarmCode${data.alarmCode}`)
                } (${data.alarmCode}),`
              )
              .replace(
                "{Time}",
                formatDateTime(data.serverAlarmTime, "HH:mm:ss, MMMM Do YYYY")
              )}
          </p>
          <Spacer size={16} />
          <Row type="fill">
            <IconWithInfo
              iconName="document-text"
              iconColor="Primary-700"
              addIconBg
              bodyComponents={
                <>
                  <span>{data.globalAlarmId}</span>
                  <CopyToClipboard text={data.globalAlarmId} />
                </>
              }
              headerText={t("AlarmHistory:global_alarm_id")}
            />
          </Row>
          <Spacer size={24} />
          <h3>{t("AlarmHistory:summary")}</h3>
          <Spacer size={18} />
          <div className={styles.alarmInfoWrapper}>
            <IconWithInfo
              iconName="alarm-reception"
              iconColor="Primary-700"
              addIconBg
              bodyComponents={
                <span>
                  {user.config?.show.includes("M:Configure/AlarmReception") ? (
                    <a
                      href={`/adminportal/alarm-receptions/${data.responseCenterId}`}
                    >
                      {data.responseCenter}
                    </a>
                  ) : (
                    data.responseCenter
                  )}
                </span>
              }
              headerText={t("AlarmHistory:received_by")}
            />

            {data?.operator && (
              <IconWithInfo
                iconName="phone-incoming"
                iconColor="Primary-700"
                addIconBg
                bodyComponents={<span>{data.operator}</span>}
                headerText={t("AlarmHistory:handled_by")}
              />
            )}

            {data?.deactivationTime && data?.serverAlarmTime && (
              <IconWithInfo
                iconName="clock"
                iconColor="Primary-700"
                addIconBg
                bodyComponents={<span>{calculateHandlingTime()}</span>}
                headerText={t("AlarmHistory:total_handling_time")}
              />
            )}
          </div>
          {(data?.responseTime || !!data.timeUntilVisible) && (
            <>
              <Spacer size={28} />
              <div className={styles.alarmInfoWrapper}>
                {!!data?.responseTime && (
                  <IconWithInfo
                    iconName="arrow-circle-right"
                    iconColor="Primary-700"
                    addIconBg
                    bodyComponents={
                      <span>{`${data.responseTime}${t(
                        "AlarmHistory:seconds_shortcut"
                      )}`}</span>
                    }
                    headerText={t("AlarmHistory:response_time")}
                  />
                )}

                {!!data?.timeUntilVisible && (
                  <IconWithInfo
                    iconName="user"
                    iconColor="Primary-700"
                    addIconBg
                    bodyComponents={`${data?.timeUntilVisible}${t(
                      "AlarmHistory:seconds_shortcut"
                    )}`}
                    headerText={t("AlarmHistory:time_until_visible")}
                  />
                )}
              </div>
            </>
          )}
          <Spacer size={28} />
          <div className={styles.alarmInfoWrapper}>
            <IconWithInfo
              iconName="key"
              iconColor="Primary-700"
              addIconBg
              bodyComponents={<span>{data.password}</span>}
              headerText={t("Common:login_input_password")}
            />

            {data?.productName && (
              <IconWithInfo
                iconName="rss"
                iconColor="Primary-700"
                addIconBg
                bodyComponents={<span>{data?.productName}</span>}
                headerText={t("Common:product_name")}
              />
            )}

            {data?.transmitterTelephoneNumber && (
              <IconWithInfo
                iconColor="Primary-700"
                addIconBg
                bodyComponents={<span>{data?.transmitterTelephoneNumber}</span>}
                headerText={t("AlarmHistory:transmitter_identifier")}
              />
            )}
          </div>
          <Spacer size={34} />
          {data.textMessage ? (
            <>
              <Information
                title={t("AlarmHistory:additional_transmitter_info")}
                description={data.textMessage}
              />
              <Spacer size={44} />
            </>
          ) : null}

          <Row type="space">
            <Tabs items={tabsData} onSelect={setCurrentTabId} />
            {config?.show.includes("SkygdSuperAdmin") && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  style={{
                    fontFamily: "Poppins-SemiBold",
                    fontSize: "14px",
                  }}
                  target="_blank"
                  href={logLink}
                  rel="noreferrer"
                >
                  {t("AlarmHistory:goto_log")}
                </a>
                <Spacer size={8} />
                <Icon name="external-link" color="Primary-700" />
              </div>
            )}
          </Row>
          {renderCurrentTab()}
        </div>
      </>
    );

  return (
    <>
      {isLoading || isAttachmentLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        renderModalBodyContent()
      )}
    </>
  );
};
