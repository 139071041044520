import { useRef, useState } from "react";
import { PeriodicInteractionResponse } from "../../../../core/api/interactions/types";
import useLanguage from "../../../../core/i18n/useLanguage";
import useTranslations from "../../../../core/i18n/useTranslations";
import Modal, { ModalActions } from "../../../../ui-lib/components/Popup/Modal";
import Table from "../../../../ui-lib/components/Tables/Table";
import {
  CustomTableCell,
  IntervalCell,
  TimeCell,
} from "./CustomTableCell/CustomTableCell";
import { DeleteInteractionModal } from "./DeleteInteractionModal";
import { getWeekDays } from "../../../../ui-lib/utils/userDateFormat";

const getTimeString = ({
  hours,
  minutes,
  locale,
}: {
  hours: number;
  minutes: number;
  locale: string;
}) => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  return date.toLocaleTimeString(locale, {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const InteractionTable = ({
  objectId,
  interactions,
  onInteractionUpdate,
}: {
  objectId: string;
  interactions: PeriodicInteractionResponse[];
  onInteractionUpdate: () => void;
}) => {
  const t = useTranslations();
  const language = useLanguage();
  const locale = language.currentLanguage?.region || "sv-SE";

  const WEEKDAYS = getWeekDays();

  const deleteModalRef = useRef<ModalActions>(null);
  const [upForDelete, setUpForDelete] = useState<number | undefined>();

  return (
    <>
      <Table<PeriodicInteractionResponse>
        columns={[
          {
            fieldTemplate: (rowData) => (
              <CustomTableCell
                label={t(`Objects:periodic_interactions_type_${rowData.type}`)}
                description={rowData.timezone}
              />
            ),
          },
          {
            fieldTemplate: (rowData) => (
              <TimeCell
                timeString={getTimeString({
                  hours: rowData.hour,
                  minutes: rowData.minute,
                  locale,
                })}
                responseTime={`${rowData.maxResponseTime} min`}
              />
            ),
          },
          {
            fieldTemplate: (rowData) => {
              const weekdays = [
                { label: WEEKDAYS[0], isSelected: rowData.onMonday },
                { label: WEEKDAYS[1], isSelected: rowData.onTuesday },
                { label: WEEKDAYS[2], isSelected: rowData.onWednesday },
                { label: WEEKDAYS[3], isSelected: rowData.onThursday },
                { label: WEEKDAYS[4], isSelected: rowData.onFriday },
                { label: WEEKDAYS[5], isSelected: rowData.onSaturday },
                { label: WEEKDAYS[6], isSelected: rowData.onSunday },
              ];

              return (
                <IntervalCell
                  interval={
                    rowData.continueNextWeek
                      ? t("Objects:interactions_repeat")
                      : t("Objects:interactions_no_repeat")
                  }
                  weekdays={weekdays}
                />
              );
            },
          },
        ]}
        items={interactions}
        rowActions={[
          {
            text: t("Objects:remove_from"),
            icon: "x",
            iconVariant: "secondary",
            iconSize: 16,
            onClick: (rowData) => {
              setUpForDelete(rowData.id);
              deleteModalRef?.current?.open();
            },
          },
        ]}
        hideHeader
        hideEmptyMessage
        noRowsMessage={t("Table:no_records_found")}
        showRowHover
        withShowMore
        showScrollAfter={5}
      />

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteInteractionModal
            objectId={objectId}
            interactionId={upForDelete}
            onClose={close}
            onDelete={() => {
              close();
              onInteractionUpdate();
            }}
          />
        )}
      </Modal>
    </>
  );
};
