import { observer } from "mobx-react";
import { ReactNode, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Column, Row } from "../../../components/Layout/Layout";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import {
  useDateTimeFormats,
  useTimezones,
} from "../../../core/api/administrators/administrators";
import {
  AdministratorDetails,
  DateTimeFormat,
  Timezone,
} from "../../../core/api/administrators/types";
import { useStoredTableState } from "../../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../../core/i18n/useTranslations";
import { SaveObjectProvider } from "../../../core/SaveObjectContext/SaveObjectContext";
import Divider from "../../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import styles from "../../Layout.module.css";
import { PAGE_ID as parentId } from "../Administrators";
import AdministratorActions from "./AdministratorActions";
import General from "./General/General";
import Permissions from "./Permissions/Permissions";
import { SharedAdministratorProvider } from "./SharedAdministratorContext/SharedAdministratorContext";
import useSharedAdministrator from "./SharedAdministratorContext/useSharedAdministrator";
import useUser from "../../../core/user/useUser";
import { MenuAlternatives } from "./MenuAlternatives/MenuAlternatives";
import { TOTPModal } from "./TOTPModal";
import { Settings } from "./Settings/Settings";
import RespondentGroups from "./RespondentGroups/RespondentGroups";

const parentPath = "/adminportal/administrators";

const AdministratorDetailsWrapper = ({
  data,
  children,
}: {
  data: AdministratorDetails;
  children: ReactNode;
}) => {
  const user = useUser();
  return (
    <Row align="start" style={{ width: "100%" }}>
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <PageHeader
          title={data.username}
          userName={user.data?.username}
          icon={
            data.type === "responscenter" || data.type === "respondent"
              ? "phone-incoming"
              : "user"
          }
        >
          <AdministratorActions administratorData={data} identity={false} />
        </PageHeader>
        <Divider />
        <Column
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            type="top"
            align="start"
            style={{ padding: "32px" }}
          >
            {children}
          </Column>
        </Column>
      </Column>
    </Row>
  );
};

const AdminDetails = ({
  timezones,
  dateTimeFormats,
  access,
}: {
  timezones: Timezone[];
  dateTimeFormats: DateTimeFormat[];
  access: { menuAlternatives: boolean };
}) => {
  const { path } = useRouteMatch();
  const { data = {} as AdministratorDetails } = useSharedAdministrator();

  return (
    <Switch>
      {access.menuAlternatives && (
        <Route path={`${path}/menu-alternatives`}>
          <AdministratorDetailsWrapper data={data}>
            <MenuAlternatives adminId={data.id} />
          </AdministratorDetailsWrapper>
        </Route>
      )}
      <Route path={`${path}/permissions`}>
        <AdministratorDetailsWrapper data={data}>
          <Permissions />
        </AdministratorDetailsWrapper>
      </Route>
      {(data.type === "respondent" || data.type === "responscenter") && (
        <Route path={`${path}/groups`}>
          <AdministratorDetailsWrapper data={data}>
            <RespondentGroups />
          </AdministratorDetailsWrapper>
        </Route>
      )}
      {(data.type === "respondent" || data.type === "responscenter") && (
        <Route path={`${path}/settings`}>
          <AdministratorDetailsWrapper data={data}>
            <Settings id={data.id} />
          </AdministratorDetailsWrapper>
        </Route>
      )}
      <Route path={path}>
        <AdministratorDetailsWrapper data={data}>
          <General
            timezones={timezones}
            dateTimeFormats={dateTimeFormats}
            data={data}
          />
        </AdministratorDetailsWrapper>
      </Route>
    </Switch>
  );
};

const AdministratorDetailsLoader = () => {
  const history = useHistory();
  const { data, isError, twoFAData, setTwoFAData } = useSharedAdministrator();
  const { data: timezones } = useTimezones();
  const { data: dateTimeFormats } = useDateTimeFormats();
  const { setSubNav } = useSubNav();
  const { url } = useRouteMatch();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;
  const { config } = useUser();
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();

  const sessionPrevPath = sessionStorage.getItem("prevPath");
  const prevPath = sessionPrevPath ? JSON.parse(sessionPrevPath) : undefined;

  const viserPermission = config?.show.includes("SensioHideFields");

  const subMenuItems = () => {
    const access = {
      menuAlternatives: !!config?.show.includes("ApplicationMenuAlternatives"),
    };

    const linksArray = [{ key: "Menu:general", to: url }];

    if (
      !(data?.organizationId && data.responseCenterId === 1) &&
      !viserPermission
    ) {
      linksArray.push({
        key: "Menu:Administrators_Permissions",
        to: `${url}/permissions`,
      });
    }

    if (
      (data?.type === "respondent" || data?.type === "responscenter") &&
      !viserPermission
    ) {
      linksArray.push({
        key: "Administrator:groups_title",
        to: `${url}/groups`,
      });

      linksArray.push({
        key: "Administrator:settings_title",
        to: `${url}/settings`,
      });
    }

    if (access.menuAlternatives && !viserPermission) {
      linksArray.push({
        key: "Menu:Menu_Alternatives",
        to: `${url}/menu-alternatives`,
      });
    }
    return { linksArray, access };
  };

  const { access } = subMenuItems();

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  useEffect(() => {
    const { linksArray } = subMenuItems();
    const nav = {
      menu: linksArray,
      backUrl: prevPath?.path || backUrl,
      backText: prevPath?.prevText || t("Menu:administrators"),
    };

    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, [id, data]);

  if (!data || !timezones || !dateTimeFormats) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      <TOTPModal
        onClose={() => {
          setTwoFAData(undefined);
        }}
        isOpen={!!twoFAData}
        data={twoFAData}
      />
      <AdminDetails
        timezones={timezones}
        dateTimeFormats={dateTimeFormats}
        access={access}
      />
    </>
  );
};

const AdministratorDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <SharedAdministratorProvider id={id}>
      <SaveObjectProvider>
        <AdministratorDetailsLoader />
      </SaveObjectProvider>
    </SharedAdministratorProvider>
  );
};

export default observer(AdministratorDetailsContainer);
