import { observer } from "mobx-react";
import { ReactNode, useEffect } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Column, Row } from "../../../components/Layout/Layout";
import useTranslations from "../../../core/i18n/useTranslations";
import Divider from "../../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import styles from "../../Layout.module.css";
import { SharedAlarmReceptionProvider } from "./SharedAlarmReceptionContext/SharedAlarmReceptionContext";
import useSharedAlarmReception from "./SharedAlarmReceptionContext/useSharedAlarmReception";
import General from "./General/General";
import Distribution from "./Distribution/Distribution";
import AlarmHandling from "./AlarmHandling/AlarmHandling";
import AlarmReceptionActions from "./AlarmReceptionActions";
import { SaveObjectProvider } from "../../../core/SaveObjectContext/SaveObjectContext";
import {
  IReceptionSubscription,
  ResponseCenterDetails,
} from "../../../core/api/responsecenters/types";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import TechnicalSettings from "../TechnicalSettings/TechnicalSettings";
import { SharedContactsProvider } from "./Contacts/SharedContactsContext";
import Contacts from "./Contacts/Contacts";
import { useStoredTableState } from "../../../core/hooks/filters/useStoredTableState";
import { PAGE_ID as parentId } from "../AlarmReceptions";
import { CallResourcesSettings } from "./CallResourcesSettings/CallResourcesSettings";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import User from "../../../core/user/User";
import { RespondentsContainer } from "./Respondents/RespondentsContainer";
import { AlarmCodes } from "./AlarmCodes/AlarmCodes";
import { AlarmCodeRules } from "./AlarmCodeRules/AlarmCodeRules";
import AlarmReceptionIntegrations from "./AlarmReceptionIntegrations/AlarmReceptionIntegrations";
import { GetConfigResponse } from "../../../core/api/config";
import AlarmSubscriptions from "./AlarmSubscriptions/AlarmSubscriptions";

const parentPath = "/adminportal/alarm-receptions";

export const arcAccesses = (config: GetConfigResponse) => ({
  ARCView: config.show.includes("ResponseCenterViewDetails"),
  ARCEdit: config.show.includes("RcEditDetails"),
  ARCHandling: config.show.includes("RcAlarmHandling"),
  ARCSettings: config.show.includes("RcAdvancedSettings"),
  ARCCodeRules: config.show.includes("AlarmCodeRuleUser"),
  ARCDistribution: config.show.includes("RcDistributionList"),
  ARCContacts: config.show.includes("RcContacts"),
  ARCSubscription: config.show.includes("RcSubscriptions"),
  ARCMenuAlternatives: config.show.includes("ApplicationMenuAlternatives"),
  ARCEmergency: config.show.includes("ResponseCenterMenuEdit"),
  ARCWhiteList: config.show.includes("IPWhiteListEdit"),
  viserStandart: config.show.includes("SensioHideFields"),
});

const subMenuItems = (
  url: string,
  accesses: any,
  isLoading: boolean,
  subscriptions?: IReceptionSubscription[]
) => {
  let items: any[] = [];

  if (accesses.ARCView) {
    items.push({ key: "Menu:general", to: url, order: 0 });
  }

  if (accesses.ARCEdit) {
    items.push({
      key: "Menu:AlarmReceptions_Respondents",
      to: `${url}/respondents`,
      order: 1,
    });
  }

  if (accesses.ARCHandling) {
    items.push({
      key: "Menu:AlarmReceptions_AlarmHandling",
      to: `${url}/alarm-handling`,
      order: 2,
    });
  }

  if (accesses.ARCCodeRules) {
    items.push({
      key: "Menu:AlarmReceptions_AlarmCodeRules",
      to: `${url}/alarm-code-rules`,
      order: 4,
    });
  }

  if (accesses.ARCDistribution) {
    items.push({
      key: "Menu:AlarmReceptions_Distribution",
      to: `${url}/distribution`,
      order: 5,
    });
  }

  if (accesses.ARCSettings) {
    items = [
      ...items,
      {
        key: "Menu:AlarmReceptions_AlarmCodes",
        to: `${url}/alarm-codes`,
        order: 3,
      },
      {
        key: "Menu:AlarmReceptions_TechnicalSettings",
        to: `${url}/technical-settings`,
        order: 6,
      },
      {
        key: "AlarmReceptions:arс_integration",
        to: `${url}/reception-integrations`,
        order: 7,
      },
      {
        key: "Menu:AlarmReceptions_CallResourcesSettings",
        to: `${url}/call-resources-settings`,
        order: 9,
      },
    ];
  }

  if (accesses.ARCContacts) {
    items.push({
      key: "Menu:AlarmReceptions_Contacts",
      to: `${url}/contacts`,
      order: 8,
    });
  }

  items.push({
    key: "Menu:AlarmReceptions_Subscriptions",
    to: `${url}/subscriptions`,
    ...(isLoading
      ? { icon: !subscriptions?.length ? "exclamation-circle" : "" }
      : {}),
    order: 10,
  });

  items = items.sort((a, b) => a.order - b.order);

  return items;
};

const AlarmReceptionDetailsWrapper = ({
  data,
  children,
  user,
}: {
  data: ResponseCenterDetails;
  children: ReactNode;
  user: User;
}) => (
  <Row align="start" style={{ width: "100%" }}>
    <Column
      style={{ width: "100%", height: "100vh", overflow: "hidden" }}
      type="top"
    >
      <PageHeader
        title={data.name}
        userName={user.data?.username}
        icon="alarm-reception"
      >
        <AlarmReceptionActions
          responseCenterId={data.id}
          parentPath={parentPath}
        />
      </PageHeader>
      <Divider />
      <Column
        className={styles.wrapper}
        style={{
          height: "auto",
          overflowY: "auto",
        }}
      >
        <Column
          className={styles.content}
          type="top"
          align="start"
          style={{ padding: "32px" }}
        >
          {children}
        </Column>
      </Column>
    </Column>
  </Row>
);

const AlarmReceptionDetails = ({ user }: { user: User }) => {
  const { path } = useRouteMatch();
  const { data } = useSharedAlarmReception();
  const { id } = useParams<{ id: string }>();

  const accesses = arcAccesses(user.config!);

  return (
    <Switch>
      {accesses.ARCEdit && (
        <Route path={`${path}/respondents`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <RespondentsContainer />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCHandling && (
        <Route path={`${path}/alarm-handling`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <AlarmHandling />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCSettings && (
        <Route path={`${path}/alarm-codes`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <AlarmCodes responseCenterId={id} />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCSettings && (
        <Route path={`${path}/reception-integrations`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <AlarmReceptionIntegrations id={id as unknown as number} />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCCodeRules && (
        <Route path={`${path}/alarm-code-rules`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <AlarmCodeRules responseCenterId={id} />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCDistribution && (
        <Route path={`${path}/distribution`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <Distribution />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCSettings && (
        <Route path={`${path}/technical-settings`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <TechnicalSettings />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      {accesses.ARCContacts && (
        <Route path={`${path}/contacts`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <SharedContactsProvider id={data!.id!}>
              <Contacts />
            </SharedContactsProvider>
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}

      {accesses.ARCSettings && (
        <Route path={`${path}/call-resources-settings`}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <CallResourcesSettings />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
      <Route path={`${path}/subscriptions`}>
        <AlarmReceptionDetailsWrapper data={data!} user={user}>
          <AlarmSubscriptions id={data!.id!} />
        </AlarmReceptionDetailsWrapper>
      </Route>
      {accesses.ARCView && (
        <Route path={path}>
          <AlarmReceptionDetailsWrapper data={data!} user={user}>
            <General />
          </AlarmReceptionDetailsWrapper>
        </Route>
      )}
    </Switch>
  );
};

const AlarmReceptionDetailsLoader = ({ user }: { user: User }) => {
  const t = useTranslations();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const sessionPrevPath = sessionStorage.getItem("prevPath");
  const tableState = useStoredTableState(parentId);
  const accesses = arcAccesses(user.config!);
  const { data, subscriptions, isLoading, isError, errorMessage } =
    useSharedAlarmReception();

  const getBackUrl = () => {
    if (sessionPrevPath) {
      const parsedPath = JSON.parse(sessionPrevPath);
      return {
        path: parsedPath.path,
        name: parsedPath.pathName,
      };
    }
    if (tableState) {
      return {
        path: `${parentPath}${tableState}`,
        name: t("Menu:alarmreceptions"),
      };
    }
    return {
      path: parentPath,
      name: t("Menu:alarmreceptions"),
    };
  };

  useEffect(() => {
    if (isError) {
      notify({
        variant: "error",
        message: errorMessage,
      });
      history.replace(parentPath);
    }
  }, [isError]);

  useEffect(() => {
    const navItems = subMenuItems(url, accesses, isLoading, subscriptions);
    const nav = {
      menu: !accesses.viserStandart
        ? navItems
        : navItems.filter((i) => i.to.includes("contacts")),
      backUrl: getBackUrl().path,
      backText: getBackUrl().name,
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, [subscriptions]);

  if (isLoading || !data) {
    return <LoadingSpinner theme="primary" />;
  }

  return <AlarmReceptionDetails user={user} />;
};

const AlarmReceptionDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const user = useUser();

  return (
    <SharedAlarmReceptionProvider id={id}>
      <SaveObjectProvider>
        <AlarmReceptionDetailsLoader user={user} />
      </SaveObjectProvider>
    </SharedAlarmReceptionProvider>
  );
};

export default observer(AlarmReceptionDetailsContainer);
