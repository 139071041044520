import { forwardRef, useState, useImperativeHandle, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import StyledModal from "../../../../ui-lib/components/Modal/Modal";
import { isEmpty, notifyApiErrors } from "../../../../core/helpers/helpers";
import useTranslations from "../../../../core/i18n/useTranslations";
import { AlarmReceptionIntegration } from "../../../../core/api/responsecenters/types";
import {
  Column,
  FillTwoColRow,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../../components/Layout/Layout";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import Checkbox, {
  ICheckbox,
} from "../../../../ui-lib/components/Checkbox/Checkbox";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import TimePicker from "../../../../ui-lib/components/DateAndTime/TimePicker";
import {
  eventTriggers,
  periodicity,
} from "../../../../core/constants/alarmReceptions";
import { IDropdownItem } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import InputContainer from "../../../../ui-lib/components/Inputs/InputContainer";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import DropdownWithCheckboxes from "../../../../ui-lib/components/Dropdown/DropdownWIthCheckboxes/DropdownWIthCheckboxes";
import useUser from "../../../../core/user/useUser";
import { getAlarmReceptionIntegration } from "../../../../core/api/responsecenters/responsecenters";
import { validateCommaSeparadedNumbers } from "../../../../core/helpers/validation";
import MultiCodeInput from "../../../../ui-lib/components/MultiInput/MultiCodeInput";

export declare type EditIntegrationActions = {
  open: (integrationId?: number) => void;
};
export interface EditIntegrationProps {
  onSubmit?: () => void;
  onClose?: () => void;
  saveButtonTitle: string;
  editIntegration: (integration: AlarmReceptionIntegration) => Promise<void>;
  id: string | number;
}
export const EditAlarmReceptionIntegration = forwardRef<
  EditIntegrationActions,
  EditIntegrationProps
>((props: EditIntegrationProps, ref) => {
  const { onSubmit, onClose, saveButtonTitle, editIntegration, id } = props;
  const t = useTranslations();

  const [title, setTitle] = useState("");
  const { authenticatedRequest } = useUser();
  const [isEditIntegrationOpen, setIsEditIntegrationOpen] = useState(false);
  const intId = useRef<number | undefined>(undefined);
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm<AlarmReceptionIntegration>({
    defaultValues: {
      separators: ";=",
      id: undefined,
      delay: "0",
    },
  });
  const periodicIntervalItems: IDropdownItem[] = periodicity.map((minutes) => ({
    id: minutes,
    name: t(`AlarmReceptions:PeriodicInterval_${minutes}`),
    isSelected: getValues("periodicInterval") === minutes,
  }));
  const eventsToTriggerItems: ICheckbox[] = eventTriggers.map(
    (event, index) => ({
      id: index,
      label: t(
        `AlarmReceptions:EventTrigger_${
          event.charAt(0).toUpperCase() + event.slice(1)
        }`
      ),
      checked: getValues(event),
    })
  );
  const closeModal = () => {
    setIsEditIntegrationOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  const submit = async (data: AlarmReceptionIntegration) => {
    try {
      let postData = {
        ...data,
        TimeStart: data.timeStart ? data.timeStart.replace(":", "") : undefined,
        TimeEnd: data.timeEnd ? data.timeEnd.replace(":", "") : undefined,
      };
      if (intId.current !== undefined) {
        postData = { ...postData, id: intId.current };
      }

      await editIntegration(postData);
      onSubmit?.();
      closeModal();
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const commaSeparatedNumbersOrEmpty = (value?: any) =>
    isEmpty(value) || validateCommaSeparadedNumbers(value);

  useImperativeHandle<EditIntegrationActions, EditIntegrationActions>(
    ref,
    () => {
      const actions: EditIntegrationActions = {
        open: async function open(integrationId?: number) {
          intId.current = integrationId;
          let data: AlarmReceptionIntegration = {
            timeEnd: undefined,
            timeStart: undefined,
            separators: ";=",
            id: undefined,
            delay: "0",
          };
          if (integrationId) {
            setTitle(t("AlarmReceptions:edit_integration_title"));
          } else {
            setTitle(t("AlarmReceptions:add_new_integration"));
          }

          if (integrationId !== undefined) {
            const integration = await getAlarmReceptionIntegration(
              id,
              integrationId!,
              authenticatedRequest
            );
            data = { ...integration.data };
          }

          if (data.timeStart?.length === 4) {
            data.timeStart = `${data.timeStart.substring(
              0,
              2
            )}:${data.timeStart.substring(2, 4)}`;
          }
          if (data.timeEnd?.length === 4) {
            data.timeEnd = `${data.timeEnd.substring(
              0,
              2
            )}:${data.timeEnd.substring(2, 4)}`;
          }
          reset(data);
          setIsEditIntegrationOpen(true);
        },
      };
      return actions;
    }
  );
  return (
    <>
      <StyledModal
        onClose={closeModal}
        isOpen={isEditIntegrationOpen}
        cancelBtnText={t("Common:cancel")}
        approveBtnText={saveButtonTitle}
        modalTitle={title}
        onApproveBtnClick={handleSubmit(submit)}
        onCancelBtnClick={closeModal}
        closeOnDocumentClick={false}
        isLoading={isSubmitting}
      >
        <p>{t("AlarmReceptions:edit_emergencycenter_setting_description")}</p>
        <Spacer size={16} />
        <form onSubmit={handleSubmit(submit)}>
          <Column type="top">
            <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
              <TwoColGrid>
                <Controller
                  name="alarmCodes"
                  control={control}
                  rules={{
                    validate: commaSeparatedNumbersOrEmpty,
                  }}
                  render={({ field }) => {
                    const length = field.value?.length ?? 0;
                    const values =
                      length > 0
                        ? field.value
                            ?.split(",")
                            .map((stringValue: any) => stringValue)
                        : [];

                    return (
                      <MultiCodeInput
                        label={t(
                          "AlarmReceptions:advanced_settings_addintegration_alarmcodes_label"
                        )}
                        placeholder={t("AlarmHistory:alarm_codes_input")}
                        values={values}
                        setValues={(updated) => {
                          const value = updated
                            .map((number) => Math.trunc(+number))
                            .join(",");
                          setValue(field.name, value, { shouldDirty: true });
                        }}
                        validationError={
                          errors.alarmCodes && t("Errors:input_field_required")
                        }
                      />
                    );
                  }}
                />
                <Controller
                  name="command"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_command_label"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.command &&
                        t(
                          "AlarmReceptions:advanced_settings_addintegration_command_required"
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="invertAlarmCodes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      style={{ paddingLeft: 0 }}
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_exclude_label"
                      )}
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Spacer size={0} />
                <FillTwoColRow>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        label={t(
                          "AlarmReceptions:advanced_settings_addintegration_comment_label"
                        )}
                        value={field.value}
                        placeholder={t(
                          "AlarmReceptions:advanced_settings_addintegration_comment_placeholder"
                        )}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FillTwoColRow>
                <Controller
                  name="delay"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (Number.isInteger(parseInt(value!))) {
                        return true;
                      }
                      return false;
                    },
                  }}
                  render={({ field }) => (
                    <NumberInput
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_delay_label"
                      )}
                      value={
                        Number.isInteger(parseInt(field.value!))
                          ? field.value
                          : undefined
                      }
                      pinnedText={t("Common:second_short")}
                      placeholder="1..3600"
                      min={0}
                      max={3600}
                      pinnedTextPosition="right"
                      onChange={field.onChange}
                      validationError={
                        errors.delay && t("Transmitters:validation_logtext")
                      }
                    />
                  )}
                />
                <Controller
                  name="args"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_args_label"
                      )}
                      value={field.value}
                      placeholder={t(
                        "AlarmReceptions:advanced_settings_addintegration_args_placeholder"
                      )}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="separators"
                  control={control}
                  rules={{
                    required: true,
                    minLength: 1,
                    maxLength: 2,
                  }}
                  render={({ field }) => (
                    <TextInput
                      required
                      inputRef={field.ref}
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_separators_label"
                      )}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.separators &&
                        t(
                          "AlarmReceptions:advanced_settings_addintegration_separator_required"
                        )
                      }
                    />
                  )}
                />
                <Spacer size={0} />
                <Controller
                  name="timeStart"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_timestart_label"
                      )}
                      value={field.value}
                      placeholder={t(
                        "AlarmReceptions:advanced_settings_addintegration_timestart_placeholder"
                      )}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="timeEnd"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label={t(
                        "AlarmReceptions:advanced_settings_addintegration_timeend_label"
                      )}
                      value={field.value}
                      placeholder={t(
                        "AlarmReceptions:advanced_settings_addintegration_timeend_placeholder"
                      )}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  name="periodicInterval"
                  control={control}
                  render={({ field }) => (
                    <InputContainer
                      label={
                        <label htmlFor="alarmreception_periodicity">
                          {t(
                            "AlarmReceptions:advanced_settings_addintegration_periodicity_label"
                          )}
                        </label>
                      }
                      input={
                        <Dropdown
                          id="alarmreception_periodicity"
                          items={periodicIntervalItems}
                          placeholder={t(
                            "AlarmReceptions:advanced_settings_addintegration_periodicity_placeholder"
                          )}
                          selectedItem={periodicIntervalItems.find(
                            (p) => p.isSelected
                          )}
                          onSelectItem={(item) => {
                            periodicIntervalItems.forEach((pItem) => {
                              pItem.isSelected = pItem.id === item.id;
                            });
                            setValue(field.name, +item.id);
                          }}
                        />
                      }
                    />
                  )}
                />
                <Spacer size={0} />
                <FillTwoColRow>
                  <InputContainer
                    label={
                      <label htmlFor="alarmreception_eventtrigger">
                        {t(
                          "AlarmReceptions:advanced_settings_eventtrigger_label"
                        )}
                      </label>
                    }
                    input={
                      <DropdownWithCheckboxes
                        id="alarmreception_eventtrigger"
                        containerWidth="100%"
                        items={eventsToTriggerItems}
                        placeholder={t(
                          "AlarmReceptions:advanced_settings_addintegration_eventtrigger_placeholder"
                        )}
                        getSelectedItems={() => {
                          eventsToTriggerItems.forEach((selectItem) => {
                            setValue(
                              eventTriggers[+selectItem.id],
                              selectItem.checked
                            );
                          });
                        }}
                      />
                    }
                  />
                </FillTwoColRow>
              </TwoColGrid>
            </Row>
          </Column>
        </form>
      </StyledModal>
    </>
  );
});

EditAlarmReceptionIntegration.defaultProps = {
  onSubmit: () => {},
  onClose: () => {},
};
